/* eslint-disable react/jsx-indent */
import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import Layout from '../client/common/layout/Layout';
import SEO from '../client/common/layout/SEO';

const StyledCopyrightNotices = styled.div`
  background-color: var(--secondaryDarkAlpha-color);
  color: var(--primaryLight-color);
  padding: ${em(20)};
  max-width: 600px;

  h1 {
    margin-bottom: ${em(10)};
  }
`;

export default function CopyrightNotices({ location }) {
  return (
    <Layout mainClass="page-container page-padding">
      <SEO
        title="Copyright Notices"
        description="List of copyright notices for Classic Tinker"
        location={location}
        hasSuffix
      />
      <StyledCopyrightNotices>
        <h1>Copyright Notices</h1>
        <p>
          World of Warcraft®
          <br /><br />
          ©2004 Blizzard Entertainment, Inc. All rights reserved. World of Warcraft,
          Warcraft and Blizzard Entertainment are trademarks or registered trademarks
          of Blizzard Entertainment, Inc. in the U.S. and/or other countries.
        </p>
      </StyledCopyrightNotices>
    </Layout>
  );
}
